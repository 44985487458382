.patchStatus {
  display: flex;
  justify-content: space-between;
  width: 100%;
  --svg-color: var(--color-black);

  button {
    cursor: pointer;
  }

  div {
    display: flex;
    gap: var(--spacing-8);
    align-items: center;
    white-space: nowrap;
  }

  & svg {
    width: 16px;
    height: 16px;
  }
}


.form {


  button {
    margin-top: var(--spacing-16);
    width: 100%;
  }

  .selectWrapper {
    > div > div {
      height: 48px;
    }
  }
}

.togglePatchButton {
  padding: 0;
  min-height: 0;
}

.rotate {
  transform: rotate(45deg);
}

.removePatchButton {
  background-color: transparent;
  border: none;
  padding: 0;
}