.guest_page__58_FL {
}

.guest_formWrapper__qEAcf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  width: 100%;
}

.guest_formWrapper__qEAcf form {
    width: 100%;
    max-width: var(--paragraph-width);
    padding: var(--spacing-48) 0;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

@media (min-width: 1000px) {

.guest_formWrapper__qEAcf form {
      padding-left: var(--spacing-24)
  }
    }

.guest_linkWrapper__dQIjP {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: var(--spacing-8);
  padding-top: var(--spacing-24);
}

.guest_linkWrapper__dQIjP {
      font-family: var(--font-beausite);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}

/* TODO: on some screens it is not consistent and it is centered on desktop */

@media (min-width: 1000px) {

.guest_linkWrapper__dQIjP {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start
}
  }

.loginForm_loginOptions__phsbe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: var(--spacing-8);
}

.loginForm_loginOptions__phsbe {
      font-family: var(--font-beausite);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}

.loginForm_formWrapper__pDYKy {
  padding: var(--spacing-48) var(--spacing-20);
}

.loginForm_formWrapper__pDYKy a {
    -webkit-text-decoration: underline;
    text-decoration: underline;
  }

@media (min-width: 1000px) {.registerForm_formWrapper__fUPPM {
    border-left: 1px solid var(--color-divider)
}
  }

.registerForm_loginOptions__piYHA {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: var(--spacing-8);
}

.registerForm_loginOptions__piYHA {
      font-family: var(--font-beausite);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}

.accordion_trigger__A7Q0Z {
  padding: var(--spacing-12) 0;
  width: 100%;
  text-align: left;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  border: 0;
  --svg-color: var(--color-black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
}

.accordion_trigger__A7Q0Z {
      font-family: var(--font-beausite);
      font-size: var(--body1-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}

  .accordion_trigger__A7Q0Z svg {
    width: 16px;
    height: 16px;
  }

  .accordion_trigger__A7Q0Z[data-state="open"] svg:first-of-type {
      display: none;
    }

  .accordion_trigger__A7Q0Z[data-state="open"] svg:last-of-type {
      display: block;
    }

  .accordion_trigger__A7Q0Z[data-state="closed"] svg:first-of-type {
      display: block;
    }

  .accordion_trigger__A7Q0Z[data-state="closed"] svg:last-of-type {
      display: none;
    }

.accordion_accordion__qmtFS {
  border-bottom: 1px solid var(--color-divider);
}

.accordion_content__LqYhP {
  overflow: hidden;
}

.accordion_content__LqYhP > * {
    margin-bottom: 1rem;
  }

.accordion_content__LqYhP > *:last-child {
      margin-bottom: 0;
    }

.accordion_content__LqYhP {

  padding-bottom: var(--spacing-12);
}

.accordion_content__LqYhP > div {
    padding-top: var(--spacing-24);
    padding-bottom: var(--spacing-24);
  }

.accordion_white__j5Rc_ {
  color: var(--color-white);
  border-color: var(--color-divider-contrast);
}

.accordion_white__j5Rc_ svg {
    --svg-color: var(--color-white);
  }

.accordion_white__j5Rc_ a {
    color: var(--color-white);
  }

.accordion_content__LqYhP[data-state="open"] {
  -webkit-animation: accordion_slideDown__8gxyv 300ms ease-out;
          animation: accordion_slideDown__8gxyv 300ms ease-out;
}

.accordion_content__LqYhP[data-state="closed"] {
  -webkit-animation: accordion_slideUp__yBh6b 300ms ease-out;
          animation: accordion_slideUp__yBh6b 300ms ease-out;
}

.accordion_decorator__k1qGs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: var(--spacing-16);
}

.accordion_descriptor__3cl1I {
  color: var(--color-tertiary-text);
}

@-webkit-keyframes accordion_slideDown__8gxyv {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordion_slideDown__8gxyv {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@-webkit-keyframes accordion_slideUp__yBh6b {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@keyframes accordion_slideUp__yBh6b {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

.button_button__SZafX {
  --button-padding-h: 16px;
  --button-padding-v: 12px;
  --button-fs: var(--button-size);
  --button-br: 4px;
  --button-gap: 8px;
  --svg-color: var(--color-white);

  font-family: var(--font-beausite);
  font-size: var(--font-size-s);
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0.01;
  display: inline-block;
  min-height: 48px;
  min-width: 80px;
  -webkit-transition: all ease 150ms;
  transition: all ease 150ms;
  cursor: pointer;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: var(--button-size);
  font-size: var(--button-fs);
  background: var(--color-primary);
  color: var(--color-white);
  padding: calc(12px + 2px) 16px
    calc(12px - 2px);
  padding: calc(var(--button-padding-v) + 2px) var(--button-padding-h)
    calc(var(--button-padding-v) - 2px);
  border-radius: 4px;
  border-radius: var(--button-br);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px solid var(--color-primary);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-text-decoration: none;
  text-decoration: none;
  line-height: 20px;
  text-align: center;
}
  /* display: flex; need to figure out how to handle icons better */

  @media (min-width: 1000px) {
    .button_button__SZafX:hover:not(:disabled) {
      background: var(--color-primary-dark);
      color: var(--color-white);
      --svg-color: var(--color-white);
    }
  }

  .button_button__SZafX.button_outlined__IEjuU:not(:hover):not(:disabled) {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    --svg-color: var(--color-primary);
  }

  .button_button__SZafX:disabled {
    cursor: default;
    border: 1px solid var(--color-disabled-background);
    background-color: var(--color-disabled-background);
    color: var(--color-text-disabled);

  
  }

  .button_button__SZafX svg {
    width: 16px;
    height: 16px;
  }

  .button_button__SZafX path {
    -webkit-transition: all ease 150ms;
    transition: all ease 150ms;
  }

.button_full__i0Qrm {
  width: 100%;
  text-align: center;
}

.button_full__i0Qrm span {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

.button_spinner__2wWeW {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid var(--color-primary-light);
  border-radius: 50%;
  border-top-color: var(--color-white);
  animation: button_spin__11GgD 1s ease-in-out infinite;
  -webkit-animation: button_spin__11GgD 1s ease-in-out infinite;
}

@-webkit-keyframes button_spin__11GgD {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes button_spin__11GgD {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.button_black__viFz4 {
  background-color: var(--color-black);
  color: var(--color-white);
  border-color: var(--color-black);
}

.button_black__viFz4.button_outlined__IEjuU:not(:hover) {
    border: 1px solid var(--color-black);
    color: var(--color-black);
  }

@media (min-width: 1000px) {
    .button_black__viFz4:hover:not(.button_outlined__IEjuU) {
      background-color: var(--color-white);
      color: var(--color-black);
      --svg-color: var(--color-black);
    }
  }

.button_ghost__es_g_ {
  background-color: transparent;
  border: 0;
  color: var(--color-primary);
  --svg-color: var(--color-primary);
}

.button_ghost__es_g_ {
      font-family: var(--font-work-sans);
      font-size: var(--caption2-size);
      line-height: 1.25;
      font-weight: 400;
      letter-spacing: 0.1em;
}

@media (min-width: 1000px) {
    .button_ghost__es_g_:hover:not(.button_outlined__IEjuU) {
      background-color: var(--color-tertiary);
      --svg-color: var(--color-primary);
    }
  }

.button_simple__DlUeo {
      font-family: var(--font-beausite);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}

.button_simple__DlUeo {
  border-bottom: 0 !important;
}

.button_simple__DlUeo {
  color: var(--color-black);
  -webkit-text-decoration: none;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding: 0 0 2px;
  background: transparent;
  border: 0;
  border-radius: 0;
  min-width: 0;
  min-height: 0;
  --svg-color: var(--color-black);
}

/* Important because .button-class has border-bottom overwriting */

@media (min-width: 1000px) {
    .button_simple__DlUeo:hover:not(:disabled) {
      --svg-color: var(--color-black);
      background: transparent;
      color: var(--color-black);
    }
  }

.button_underlined__0zSUX::before,
  .button_underlined__0zSUX::after {
    width: 100%;
    content: "";
    position: absolute;
    background: var(--color-black);
    left: auto;
    display: block;
    right: 0;
    bottom: 0;
    height: 1px;
    -webkit-transition: all 200ms;
    transition: all 200ms;
    pointer-events: none;
  }

.button_underlined__0zSUX::after {
    width: 0%;
    left: 0;
    -webkit-transition-delay: 200ms;
            transition-delay: 200ms;
  }

.button_underlined__0zSUX:hover::after {
    width: 100%;
  }

.button_underlined__0zSUX:hover::before {
    width: 0%;
  }

.button_transparent__It_J9 {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-black);
  --svg-color: var(--color-primary);
}

@media (min-width: 1000px) {
    .button_transparent__It_J9:hover:not(.button_outlined__IEjuU) {
      background-color: var(--color-tertiary);
      color: var(--color-black);
      --svg-color: var(--color-primary);
    }
  }

.button_giftCard__selected__WELMR.button_giftCard__selected__WELMR {
  background-color: var(--color-tertiary) !important;
}

.button_giftCard__default__w9rcQ.button_giftCard__default__w9rcQ {
  background-color: transparent;
  color: var(--color-black);
  border-color: var(--color-tertiary);
}

.button_giftCard__default__w9rcQ.button_giftCard__default__w9rcQ:hover {
    color: var(--color-black);
    background-color: var(--color-tertiary);
  }

.button_caption__AOH73 {
  background-color: var(--color-white);
  border-color: var(--color-white);
  color: rgba(14, 14, 14, 0.64);
  --svg-color: var(--color-primary);
  text-transform: uppercase;
  min-height: 0;
}

.button_caption__AOH73 {
      font-family: var(--font-work-sans);
      font-size: var(--caption1-size);
      line-height: 1;
      font-weight: 400;
      letter-spacing: 0.1em;
}

@media (min-width: 1000px) {
    .button_caption__AOH73:hover:not(.button_outlined__IEjuU) {
      background-color: var(--color-tertiary);
      color: rgba(14, 14, 14, 0.64);
      --svg-color: rgba(14, 14, 14, 0.64);
    }
  }

.button_outlined__IEjuU:not(:hover) {
  background: transparent;
  --svg-color: var(--color-black);
}

.button_rounded__5yaxQ {
  border-radius: 100%;
  padding: 8px;
  aspect-ratio: 1;
  padding: 0;
  width: 48px;
  min-width: 0;
  min-height: 0;
  position: relative;
}

.button_rounded__5yaxQ svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 24px;
    height: 24px;
  }

.button_toggle__L9X7T {
  --svg-color: var(--color-black);

  background: transparent;
  border-color: transparent;
  color: var(--color-black);
  padding: 9px 0 7px;
  min-width: 0;
  min-height: 0;
}

@media (min-width: 1000px) {
    .button_toggle__L9X7T:not(:disabled):hover {
      background: transparent;
      border-color: transparent;
      color: var(--color-black);
      --svg-color: var(--color-black);
    }
  }

.button_toggle__L9X7T svg {
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }

.button_shadow__MqGkt {
  -webkit-box-shadow: 0px 0px 10px rgba(215, 214, 209, 0.5);
          box-shadow: 0px 0px 10px rgba(215, 214, 209, 0.5);
}

.button_icon__GhYAg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.button_label__P_ODE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.button_label__P_ODE > span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: var(--button-gap);
  }

.button_contentWrapper__FNrIx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: var(--button-gap);
}

.GiftCardForm_form___hVpe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 24px;
}

.GiftCardForm_giftCards__UXDqI {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 8px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.GiftCardForm_hide__vDSXn {
  display: none;
}

.GiftCardForm_note__7dM12 {
      font-family: var(--font-beausite);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}

.GiftCardForm_note__7dM12 {
    
    margin-top: 8px;
}

.GiftCardForm_accordion__q06m1 {
  margin-top: 40px;
}

@media (min-width: 1000px) {

.GiftCardForm_accordion__q06m1 {
    margin-top: 48px
}
  }
.breadcrumbs_container__zFR04 {
  padding: max(12px, min(0.87vw, 24px)) 0;
}

  .breadcrumbs_container__zFR04 > a,
  .breadcrumbs_container__zFR04 span {
    font-size: var(--font-size-menu-item);
    letter-spacing: 0.08em;
    line-height: calc(15 / 16);
    color: var(--color-primary-80);
  }

  .breadcrumbs_container__zFR04 {
  margin: 0 auto;
}

.breadcrumbs_small-container__BeSed {
  padding: max(12px, min(0.87vw, 24px)) 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
}

.breadcrumbs_small-container__BeSed > a,
  .breadcrumbs_small-container__BeSed span {
    font-size: var(--font-size-menu-item);
    letter-spacing: 0.08em;
    line-height: calc(15 / 16);
    color: var(--color-primary-80);
  }

.gift-wrapping_accordion__wrapper__EgEFY {
    width: 100%;
    padding-bottom: 0;
  }
  .gift-wrapping_accordion__container__A_gZp {
    width: 100%;
    border: none;
    padding-bottom: 0;
  }
  .gift-wrapping_accordion__container__A_gZp > div {
      padding-bottom: 0;
    }
  .gift-wrapping_accordion__trigger___Toks {
      font-family: var(--font-beausite);
      font-size: var(--headline2-size);
      line-height: 1.2;
      font-weight: 300;
      letter-spacing: 0.01em;
  }
  .gift-wrapping_accordion__trigger___Toks {
    font-size: 22px;
    padding: 0;
  }
  .gift-wrapping_accordion__trigger___Toks svg {
      height: 24px;
      width: 24px;
    }
  .gift-wrapping_accordion__caption__4CJiN {
    font-size: 14px;
    color: var(--color-tertiary-text);
    padding-top: 1.5rem;
    margin-bottom: 0;
  }
  @media (min-width: 1000px) {
  .gift-wrapping_accordion__caption__4CJiN {
      max-width: 90%
  }
    }

.gift-wrapping_bolded__C4aDI {
  font-weight: bold;
}
.gift-wrapping_form__imageWrapper__PPF5c {
    width: 276px;
    height: 100%;
    width: 100%;
    height: 368px;
    position: relative;
  }
.gift-wrapping_form__productWrapper__uomZY {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: var(--spacing-20);
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
.gift-wrapping_form__productWrapper__uomZY > * {
      -ms-flex-preferred-size: calc(50% - var(--spacing-20) / 2);
          flex-basis: calc(50% - var(--spacing-20) / 2);
    }
.gift-wrapping_form__product__0Wvii {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: var(--spacing-16);
    max-width: 276px;
  }
.gift-wrapping_form__product__0Wvii img {
      border-radius: 4px;
      -o-object-fit: cover;
         object-fit: cover;
      aspect-ratio: 3 / 4;
    }
.gift-wrapping_form__product__0Wvii p:not(:first-child) {
      color: var(--color-secondary-text);
    }
.gift-wrapping_form__product__0Wvii p:last-child {
      margin-top: var(--spacing-8);
    }
.gift-wrapping_form__description__EoJ6H {
    font-size: 14px;
    margin-top: 0.5rem;
    color: var(--color-tertiary-text);
  }

.gift-wrapping_giftDetailForm__FE56l {
  padding-top: var(--spacing-8);
}

.gift-wrapping_giftDetailForm__buttonGroup__hnXEf {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: var(--spacing-16);
    margin-top: var(--spacing-16);
  }

.gift-wrapping_giftDetailForm__buttonGroup__hnXEf button {
      width: 100%;
    }

.gift-wrapping_giftDetailForm__addedMessage__y7_iw {
    margin-top: var(--spacing-16);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

.gift-wrapping_giftDetailForm__addedMessage__y7_iw button {
      -webkit-text-decoration: underline;
      text-decoration: underline;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: inherit;
      font-family: inherit;
      padding: 0;
    }

.gift-wrapping_giftDetails__RlpEj {
  max-width: 70%;
}

@container (max-width: 400px) {

.gift-wrapping_giftDetails__RlpEj {
    max-width: 120px
}

    .gift-wrapping_giftDetails__RlpEj > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
.gift-wrapping_giftDetails__RlpEj > p {
      font-family: var(--font-beausite);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
}
.gift-wrapping_giftDetails__RlpEj > p {
  color: var(--color-text-secondary);
}

.quantity-selector_quantity-container__swGXj {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

  .quantity-selector_quantity-container__swGXj > button {
    border: none;
    background-color: transparent;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    padding: 0;
    min-height: 36px;
    min-width: 36px;
    margin: 2px;
    border-radius: 100%;
  }

  .quantity-selector_quantity-container__swGXj > button:hover {
      background: var(--color-tertiary);
    }

  .quantity-selector_quantity-container__swGXj > p {
    margin: 0 5px;
    padding: 0;
    min-width: 14px;
    text-align: center;
  }

.cart-item_item__b_W_2 {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: var(--spacing-16);
  position: relative;
  border-bottom: 1px solid var(--color-divider);
  padding: var(--spacing-20) 0;
  container-type: inline-size;
}

  .cart-item_item__b_W_2:last-child {
    border: 0;
  }

  .cart-item_item__b_W_2 .cart-item_details__container__eD3Rc {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .cart-item_item__meta__XRNAd {
      font-family: var(--font-beausite);
      font-size: var(--body2-size);
      line-height: 1.5;
      font-weight: 300;
      letter-spacing: 0;
  }

  .cart-item_item__meta__XRNAd {
    color: var(--color-text-secondary);
  }

  .cart-item_item__info__8rhTa {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-bottom: var(--spacing-32);
  }

  .cart-item_item__actions__gE3HM {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    gap: var(--spacing-16);
  }

.cart-item_noGap__Tkk2U {
  gap: 0;
}

.cart-item_engraving__AWCyZ {
  margin-bottom: var(--spacing-32);

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: var(--spacing-16);
}

.cart-item_toggleEngravingButton__7nF4i {
  padding: 0;
  min-height: 1em;
  --svg-color: var(--color-dark);
}

.cart-item_rotate__vfybA {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.cart-item_image__Zl6ks {
  width: 64px;
  height: 85px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  background: var(--color-tertiary);
}

.cart-item_image__Zl6ks img {
    -o-object-fit: contain;
       object-fit: contain;
    width: 100%;
    height: 100%;
    padding: 4px;
  }

.cart-item_details__container_wrap__JpIn1 {
  width: 100%;
}

.cart-item_left__column___hekU {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.cart-item_right__column__yJDNw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 0.5rem;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.cart-item_right__column__yJDNw .cart-item_discounted__wrapper__SYPXb {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    gap: 0.5rem;
    -ms-flex-line-pack: end;
        align-content: flex-end;
  }

.cart-item_right__column__yJDNw .cart-item_discounted__wrapper__SYPXb .cart-item_discounted__price__pkpT6 {
      opacity: 0.6;
      -webkit-text-decoration: line-through;
      text-decoration: line-through;
    }

.cart-item_right__column__yJDNw .cart-item_remove__button__rI13I {
    --remove-button-padding: 0.5rem;
    position: relative;
    padding: 0.5rem;
    padding: var(--remove-button-padding);
    padding-right: 0;
  }

.cart-item_right__column__yJDNw .cart-item_remove__button__rI13I::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      left: var(--remove-button-padding);
      top: auto;
      bottom: var(--remove-button-padding);
      width: calc(100% - var(--remove-button-padding));
      height: 1px;
      background-color: var(--color-black);
      margin-left: auto;
      margin-right: auto;
      -webkit-transition: all 0.15s ease;
      transition: all 0.15s ease;
    }

.cart-item_right__column__yJDNw .cart-item_remove__button__rI13I:hover::after {
        width: 0;
      }

.cart-item_quantity__wrapper__HBrkO {
  border: 1px solid var(--color-divider);
  border-radius: 4px;
}

.cart-item_move-button-span__wJ4qI {
  --remove-button-padding: 0rem;
  position: relative;
  padding: 0rem;
  padding: var(--remove-button-padding);
  padding-right: 0;
}

.cart-item_move-button-span__wJ4qI::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    left: var(--remove-button-padding);
    top: auto;
    bottom: var(--remove-button-padding);
    width: calc(100% - var(--remove-button-padding));
    height: 1px;
    background-color: var(--color-black);
    margin-left: auto;
    margin-right: auto;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }

.cart-item_move-button-span__wJ4qI:hover::after {
      width: 0;
    }

.cart-item_move-button__w47KU {
  position: absolute;
  bottom: 0;
  padding: 0;
  width: 100%;
  text-align: left;
}

.engraving_engraving__GKQdT {
  margin: var(--spacing-20) 0;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: var(--spacing-16);
}

.engraving_toggleEngravingButton__RrvXH {
  padding: 0;
  min-height: 1em;
  --svg-color: var(--color-dark);
}

.engraving_rotate__rQJVR {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.engraving_engravingStatus__VtDZw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  --svg-color: var(--color-black);
}

.engraving_engravingStatus__VtDZw div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: var(--spacing-8);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

.engraving_engravingStatus__VtDZw svg {
    width: 16px;
    height: 16px;
  }

.engraving_crossIcon__H2fz0 {
  cursor: pointer;
}

.engraving_customisationModal__GXlp2 {
  margin-top: 0;
  padding-bottom: var(--spacing-24);

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: var(--spacing-8);
}

.engraving_customisationModal__GXlp2 form {
    padding-top: var(--spacing-16);
  }

.engraving_customisationModal__GXlp2 h3 {
    text-transform: uppercase;
    margin-bottom: var(--spacing-8);
  }

.engraving_customisationModal__GXlp2 h3 {
      font-family: var(--font-work-sans);
      font-size: var(--subtitle2-size);
      line-height: 1.5;
      font-weight: 400;
      letter-spacing: 0.1em;
  }

.engraving_customisationModal__GXlp2 .engraving_tabBar__j_5AK {
    margin-bottom: var(--spacing-8);
  }

.engraving_customisationModal__GXlp2 .engraving_tabBar__j_5AK > div {
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.08em;
      padding: var(--spacing-8) 0;
      color: var(--color-primary);
      font-family: var(--font-work-sans);
    }

.engraving_customisationModal__GXlp2 .engraving_blokContainer__rVv60 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: var(--spacing-16);

  }

.engraving_customisationModal__GXlp2 .engraving_divider__45I2a {
    border-bottom: 1px solid var(--color-divider);
    margin-bottom: var(--spacing-20);
    margin-top: var(--spacing-40);
  }

.engraving_customisationModal__GXlp2 .engraving_contained__ILmpL {
    padding: 0 var(--spacing-24);
  }

.engraving_crossIcon__H2fz0 {
  cursor: pointer;
}


.engraving_modalGallery__8nG_8 {
  margin-top: var(--spacing-16);
}

.engraving_modalGallery__8nG_8 .engraving_slide__MSt2N {
  --slide-width: 70%;
  width: 70%;
  width: var(--slide-width);
}

.engraving_modalGallery__8nG_8 .engraving_slide__MSt2N img {
    border-radius: 4px;
  }

.engraving_modalGallery__8nG_8 .engraving_slide__MSt2N:first-child {
    padding-left: var(--smart-page-inset);
    width: calc(
      var(--slide-width) + var(--smart-page-inset) - var(--column-gap) / 2
    );
  }

@media (min-width: 1000px) {

.engraving_modalGallery__8nG_8 .engraving_slide__MSt2N:first-child {
      padding-left: var(--page-inset);
      width: calc(var(--slide-width) + var(--page-inset))
  }
    }

.engraving_modalGallery__8nG_8 .engraving_slide__MSt2N:last-child:not(:first-child) {
    padding-right: var(--smart-page-inset);
    width: calc(var(--slide-width) + var(--smart-page-inset));
  }

@media (min-width: 1000px) {

.engraving_modalGallery__8nG_8 .engraving_slide__MSt2N:last-child:not(:first-child) {
      padding-right: var(--page-inset);
      width: calc(var(--slide-width) + var(--page-inset))
  }
    }

.patch_patchStatus__zGwMi {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  --svg-color: var(--color-black);
}

  .patch_patchStatus__zGwMi button {
    cursor: pointer;
  }

  .patch_patchStatus__zGwMi div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: var(--spacing-8);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
  }

  .patch_patchStatus__zGwMi svg {
    width: 16px;
    height: 16px;
  }


.patch_form__dOu2o button {
    margin-top: var(--spacing-16);
    width: 100%;
  }


.patch_form__dOu2o .patch_selectWrapper__0lGdj > div > div {
      height: 48px;
    }

.patch_togglePatchButton__IPNEp {
  padding: 0;
  min-height: 0;
}

.patch_rotate__Okq_N {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.patch_removePatchButton__v7Gy4 {
  background-color: transparent;
  border: none;
  padding: 0;
}

.input_label__CXZ6C {
  --transition: 200ms;
  --input-font-size: var(--font-size-s);
  --input-padding-v: 0.5rem;
  --input-padding-h: 0;
  --input-br: 0px;

  position: relative;
  display: block;
  width: 100%;
}

  @media (min-width: 1000px) {.input_label__CXZ6C {
    --input-font-size: var(--font-size-xs);
    --input-padding-v: 0.5rem;
    --input-padding-h: 0
}
  }

  .input_label__CXZ6C > button > svg path {
    stroke-width: 1px;
    stroke: var(--color-primary);
    color: var(--color-primary);
  }

  .input_label__CXZ6C > .input_label__CXZ6C > button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 var(--input-padding-h);
    background: transparent;
    border: none;
    border-radius: var(--input-br);
    cursor: pointer;
  }

.input_input__5fnmL {
  background: inherit;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: var(--input-padding-v) var(--input-padding-h);
  border: 0;
  border-bottom: 1px solid var(--color-primary);
  width: 100%;
  font-size: var(--input-font-size);
  -webkit-transition: all var(--transition);
  transition: all var(--transition);
  line-height: var(--line-height);
}

.input_input__5fnmL:focus {
    -webkit-box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.02);
            box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.02);
  }

.input_input__5fnmL:focus-visible {
    outline: 0;
  }

.input_input__5fnmL.input_active__zy_qf {
    padding: calc(var(--input-padding-v) + 4px) var(--input-padding-h)
      calc(var(--input-padding-v) - 4px);
  }

.input_input__5fnmL::-moz-placeholder {
    -moz-transition: all 0.1s ease;
    transition: all 0.1s ease;
    color: var(--color-primary);
    font-size: var(--font-size-xs);
    opacity: 1;
  }

.input_input__5fnmL::-webkit-input-placeholder {
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    color: var(--color-primary);
    font-size: var(--font-size-xs);
    opacity: 1;
  }

.input_input__5fnmL:-ms-input-placeholder {
    -ms-transition: all 0.1s ease;
    transition: all 0.1s ease;
    color: var(--color-primary);
    font-size: var(--font-size-xs);
    opacity: 1;
  }

.input_input__5fnmL::-ms-input-placeholder {
    -ms-transition: all 0.1s ease;
    transition: all 0.1s ease;
    color: var(--color-primary);
    font-size: var(--font-size-xs);
    opacity: 1;
  }

.input_input__5fnmL::placeholder {
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    color: var(--color-primary);
    font-size: var(--font-size-xs);
    opacity: 1;
  }

.input_input__5fnmL:hover::-moz-placeholder {
      opacity: 0.6;
      transform: translateY(-2px);
    }

.input_input__5fnmL:hover::-webkit-input-placeholder {
      opacity: 0.6;
      -webkit-transform: translateY(-2px);
              transform: translateY(-2px);
    }

.input_input__5fnmL:hover:-ms-input-placeholder {
      opacity: 0.6;
      transform: translateY(-2px);
    }

.input_input__5fnmL:hover::-ms-input-placeholder {
      opacity: 0.6;
      transform: translateY(-2px);
    }

.input_input__5fnmL:hover::placeholder {
      opacity: 0.6;
      -webkit-transform: translateY(-2px);
              transform: translateY(-2px);
    }

